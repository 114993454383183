<template>
  <div class="memorabiliaBox" style="width: 95%; margin: auto">
    <van-steps direction="vertical" active-color="#AB0000" :active="22">
      <van-step class="contont bg2024">
        <div class="littleTip">
          <div class="timeMsg">2024年9月</div>
          <div class="contentMsg">
            <div>湖南旷真（宁波）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2024年8月</div>
          <div class="contentMsg">
            <div>湖南旷真（无锡）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2024年7月</div>
          <div class="contentMsg">
            <div>杭州市富阳区司法局领导一行莅临我所考察调研</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2024年5月</div>
          <div class="contentMsg">
            <div>湖南旷真（东莞）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2024年5月</div>
          <div class="contentMsg">
            <div>无锡市梁溪区司法局学习考察组一行莅临旷真座谈交流</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2024年4月</div>
          <div class="contentMsg">
            <div>湖南旷真（惠州）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2024年4月</div>
          <div class="contentMsg">
            <div>
              旷真受邀出席“腾讯云协作Saas智能化升级媒体沟通会”，分享我所使用腾讯乐享AI助手培养法律人才经验
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2024年3月</div>
          <div class="contentMsg">
            <div>旷真深圳分所入选首批“深圳市合规管理律师事务所”名单</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2024年2月</div>
          <div class="contentMsg">
            <div>深圳市南山区司法局领导一行莅临旷真深圳分所考察指导工作</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2024年2月</div>
          <div class="contentMsg">
            <div>
              开始进行产销研分开的组织变革，前端搭建“铁三角”体系，后端形成服务供应体系
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2024年2月</div>
          <div class="contentMsg">
            <div>
              AI赋能，提质增效，旷真荣获「2023腾讯乐享·企程奖」组织力建设典范企业
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2024年1月</div>
          <div class="contentMsg">
            <div>法治日报法治周末报社领导莅临旷真律师事务所进行调研考察</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2023">
        <div class="littleTip">
          <div class="timeMsg">2023年11月</div>
          <div class="contentMsg">
            <div>
              旷真携手举办“砼聚高质量
              砥砺新征程”——2023年中国混凝土行业高质量发展交流会，荣获“2023-2024年度协会指定混凝土行业法律顾问机构”荣誉。
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2023年10月</div>
          <div class="contentMsg">
            <div>
              旷真MIC视频号开始首次直播，与内部律师连线，开辟外部人才了解旷真的线上新渠道
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2023年10月</div>
          <div class="contentMsg">
            <div>旷真民生事业两周年庆祝仪式</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2023年5月</div>
          <div class="contentMsg">
            <div>旷真14周年“质量强所”主题所庆</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2023年3月</div>
          <div class="contentMsg">
            <div>
              旷真知识产权事业部前往厦门大学马来西亚分校，参与“RCEP框架下知识产权与创新的合作机遇”论坛。
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2023年2月</div>
          <div class="contentMsg">
            <div>旷真律师事务所2023年战略研讨会</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2023年2月</div>
          <div class="contentMsg">
            <div>湖南旷真（青岛）律师事务所正式注册成功</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2022">
        <div class="littleTip">
          <div class="timeMsg">2022年10月</div>
          <div class="contentMsg">
            <div>湖南旷真（海口）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2022年8月</div>
          <div class="contentMsg">
            <div>湖南旷真（苏州）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2022年7月</div>
          <div class="contentMsg">
            <div>
              湖南旷真律师事务所总支部召开全体党员大会，选举首届总支部委员会委员
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2022年5月</div>
          <div class="contentMsg">
            <div>湖南旷真（厦门）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2022年5月</div>
          <div class="contentMsg">
            <div>湖南旷真（银川）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2022年1月</div>
          <div class="contentMsg">
            <div>湖南旷真（沈阳）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2022年1月</div>
          <div class="contentMsg">
            <div>湖南旷真（济南）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2022年1月</div>
          <div class="contentMsg">
            <div>湖南旷真（南昌）律师事务所搬迁新址</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2022年1月</div>
          <div class="contentMsg">
            <div>湖南旷真（杭州）律师事务所搬迁新址</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2021">
        <div class="littleTip">
          <div class="timeMsg">2021年12月</div>
          <div class="contentMsg">
            <div>湖南旷真（济南）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2021年12月</div>
          <div class="contentMsg">
            <div>湖南旷真（珠海）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2021年12月</div>
          <div class="contentMsg">
            <div>
              旷真出席中国混凝土行业高质量发展交流会，荣获中国建筑业协会混凝土分会颁发的“2021-2022年度中国建筑业协会混凝土分会指定唯一混凝土行业法律服务顾问机构”
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2021年10月</div>
          <div class="contentMsg">
            <div>湖南旷真（武汉）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2021年9月</div>
          <div class="contentMsg">
            <div>湖南旷真（兰州）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2021年4月</div>
          <div class="contentMsg">
            <div>湖南旷真（郑州）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2021年4月</div>
          <div class="contentMsg">
            <div>湖南旷真（太原）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2021年3月</div>
          <div class="contentMsg">
            <div>旷真携手2021年度混凝土协会会长、秘书长联席会圆满召开</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2021年1月</div>
          <div class="contentMsg">
            <div>湖南旷真（南昌）律师事务所正式注册成功</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2020">
        <div class="littleTip">
          <div class="timeMsg">2020年12月</div>
          <div class="contentMsg">
            <div>旷真法律集团助力重庆混凝土协会第八届会员大会成功召开</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年12月</div>
          <div class="contentMsg">
            <div>旷真法律集团出席2020年深圳水泥制品协会年会并发表主题演讲</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年12月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团荣获中国建筑业混凝土分会颁发的“行业突出贡献奖”
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年12月</div>
          <div class="contentMsg">
            <div>旷真助力2020全国建筑施工机械租赁大会成功召开</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年9月</div>
          <div class="contentMsg">
            <div>
              《更喜岷山千里雪，三军过后尽开颜》旷真法律集团祝贺2020年中国建筑业协会混凝土分会及省市协会会长秘书长联席会召开
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年9月</div>
          <div class="contentMsg">
            <div>
              湖南旷真（乌鲁木齐）律师事务所受邀参加乌鲁木齐市新市区公共法律服务中心揭幕仪式
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年9月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团应邀出席2020砼享未来年会暨商砼青年企业家论坛并发表演讲
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年9月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团创始合伙人张日照先生受邀参加湖南省混凝土与水泥制品协会2020年第一次常务理事会议
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年9月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团受邀出席中国水泥行业50强高层论坛，展望水泥行业发展，畅想企业未来
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年9月</div>
          <div class="contentMsg">
            <div>旷真法律集团助力北京混凝土协会八届三次会员大会圆满召开</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年9月</div>
          <div class="contentMsg">
            <div>湖南旷真（杭州）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年9月</div>
          <div class="contentMsg">
            <div>湖南旷真（合肥）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年9月</div>
          <div class="contentMsg">
            <div>旷真法律集团助力2020第二届中国西部砂浆论坛</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年8月</div>
          <div class="contentMsg">
            <div>旷真法律集团助力2020第二届中国西部砂浆论坛</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年7月</div>
          <div class="contentMsg">
            <div>
              湖南旷真律师事务所党支部委员会组织党支部全体党员、预备党员及入党积极分子一同前往爱国主义教育圣地杨开慧纪念馆，开展“不忘初心、牢记使命”主题教育活动
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年7月</div>
          <div class="contentMsg">
            <div>湖南旷真（成都）律师事务所搬迁新址</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年5月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团参加《成都市预拌混凝土管理规定(试行)》宣贯会，集团创始合伙人徐冬梅受邀作专题讲座
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2021年3月</div>
          <div class="contentMsg">
            <div>旷真携手2021年度混凝土协会会长、秘书长联席会圆满召开</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年5月</div>
          <div class="contentMsg">
            <div>
              中共湖南旷真律师事务所召开支部委员改选大会，大会成功选举产生新一届党支部副书记和委员
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年5月</div>
          <div class="contentMsg">
            <div>湖南旷真（成都）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2021年3月</div>
          <div class="contentMsg">
            <div>旷真携手2021年度混凝土协会会长、秘书长联席会圆满召开</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年5月</div>
          <div class="contentMsg">
            <div>
              中共湖南旷真律师事务所召开支部委员改选大会，大会成功选举产生新一届党支部副书记和委员
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年5月</div>
          <div class="contentMsg">
            <div>湖南旷真（成都）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年5月</div>
          <div class="contentMsg">
            <div>旷真法律集团正式决定在福州、南宁、太原设立分支机构</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年4月</div>
          <div class="contentMsg">
            <div>湖南旷真（天津）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年4月</div>
          <div class="contentMsg">
            <div>旷真携手2021年度混凝土协会会长、秘书长联席会圆满召开</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年4月</div>
          <div class="contentMsg">
            <div>湖南旷真（天津）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年4月</div>
          <div class="contentMsg">
            <div>
              长沙市司法局党委书记、局长尹小英，市司法局党委委员、副局长、市律师行业党委书记荀飞正等司法局领导，携同长沙市律协会长江帆及市律协一行６人、长沙市有关律师事务所主任等人莅临湖南旷真律师事务所，组织召开长沙市律师工作调研座谈会
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年3月</div>
          <div class="contentMsg">
            <div>
              南京市江宁区司法局领导一行莅临湖南旷真（南京）律师事务所指导工作
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年3月</div>
          <div class="contentMsg">
            <div>湖南旷真（北京）律师事务所搬迁新址</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年3月</div>
          <div class="contentMsg">
            <div>
              湖南旷真（乌鲁木齐）律师事务所在哈密地区各商混站集中进行应收账款风险管理培训
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年3月</div>
          <div class="contentMsg">
            <div>旷真法律集团正式决定在南昌、合肥、杭州分别设立分支机构</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年2月</div>
          <div class="contentMsg">
            <div>
              湖南旷真（乌鲁木齐）律师事务所律师自愿向乌鲁木齐市慈善总会进行抗疫捐款
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年1月</div>
          <div class="contentMsg">
            <div>旷真位于长沙的总部正式迁址至长沙国金中心（IFS）</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年1月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团受邀出席广州市混凝土行业协会2019年年终总结大会
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2020年1月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团受邀出席广州市混凝土行业协会2019年年终总结大会
            </div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2019">
        <div class="littleTip">
          <div class="timeMsg">2019年12月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团参加成都混凝土协会2019年会暨第四届第六次会员大会，旷真法律集团高级合伙人王晓华、成都公司负责人徐冬梅代表出席，王晓华女士受邀发表专业报告
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年12月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团参加深圳市水泥及制品协会2019年会员大会暨行业年会，湖南旷真（深圳）律师事务所客户部长荆媛媛受邀作专题讲座
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年12月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团参加郑州市混凝土行业高峰论坛，旷真法律集团高级合伙人王晓华、郑州公司负责人金海亮代表出席
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年11月</div>
          <div class="contentMsg">
            <div>
              2019全国建筑施工机械租赁行业年会暨装配式建筑施工吊装安全技术交流会在江苏开幕，旷真法律集团受邀参加并荣获“2019全国建筑施工机械租赁行业优秀服务单位”
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年11月</div>
          <div class="contentMsg">
            <div>
              由旷真法律集团携手中国建筑业协会混凝土分会共同举办的2019年中国混凝土年会暨混凝土行业高质量发展论坛在珠海隆重举行
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年11月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团出席北京市混凝土协会八届二次会员大会，旷真法律集团北京混凝土市场总监徐晓玮受邀作行业专题报告
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年11月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团出席北京市混凝土协会八届二次会员大会，旷真法律集团北京混凝土市场总监徐晓玮受邀作行业专题报告
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年11月</div>
          <div class="contentMsg">
            <div>旷真法律集团正式决定在天津、郑州分别设立分支机构</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年11月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团出席钢之家网站2020年钢铁产业链发展形势高峰论坛，旷真法律集团高级合伙人王晓华受邀发表主题演讲
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年10月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团出席2019中国(岳阳)钢铁产业链互联网论坛，旷真法律集团湖南市场总监邹译萱作主题分享
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年10月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团出席2019中国(湘潭)钢铁产业链互联网论坛，旷真法律集团湖南市场总监邹译萱作主题分享
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年9月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团携手北京市建设工程物资协会建筑机械分会举办“租赁行业应收账款管理与风险控制讲座”，特邀旷真法律集团创始合伙人、北京分所主任张超担任此次讲座培训专家
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年9月</div>
          <div class="contentMsg">
            <div>
              天贸钢铁网和大大买钢主办召开的“2019年中国（郴州）钢铁产业链互联网论坛”举行，旷真法律集团受邀参加并作主题报告
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年9月</div>
          <div class="contentMsg">
            <div>湖南旷真（北京）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年9月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团携手成都市混凝土协会举办《商砼企业应收款法律风险管理》讲座，旷真法律集团创始合伙人、成都分支机构负责人徐冬梅作为培训专家进行了一场应收账款风险管理的精彩分享
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年9月</div>
          <div class="contentMsg">
            <div>
              湖南旷真（广州）律师事务所正式成为广州市混凝土行业协会第三届理事单位
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年8月</div>
          <div class="contentMsg">
            <div>湖南旷真（南京）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年8月</div>
          <div class="contentMsg">
            <div>
              天贸钢铁网和大大买钢主办召开的“2019年中国（浏阳）钢铁产业链互联网论坛”在湖南浏阳举行，旷真法律集团受邀参加并作主题报告
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年8月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团携手钢之家在广东佛山举办2019年钢铁产业链发展形势（广东）暨黑色金属高峰论坛
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年8月</div>
          <div class="contentMsg">
            <div>
              湖南省司法厅、湖南省律师协会正式授予湖南旷真律师事务所“湖南省优秀律师事务所”的荣誉称号
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年7月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团受邀参加湖南省金属材料商会主办的2019“创新变革”钢贸行业企业家沙龙
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年7月</div>
          <div class="contentMsg">
            <div>
              由旷真法律集团赞助、青海省混凝土行业协会主办的《青海省混凝土行业协会高质量发展论坛》在青海举行
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年7月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团携手《混凝土》杂志、各省市混凝土行业协会在厦门召开预拌混凝土行业绿色生产前沿技术及管理研讨会，旷真法律集团高级合伙人王晓华、旷真法律集团混凝土事业部总监胡刚受邀出席，胡刚先生做专题报告
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年7月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团与钢之家携手举办的2019年钢铁产业链发展形势研讨会暨西部第十八次矿煤焦钢风险管理论坛在成都顺利召开
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年7月</div>
          <div class="contentMsg">
            <div>旷真法律集团当选为广州市混凝土协会第三届会员大会理事单位</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年6月</div>
          <div class="contentMsg">
            <div>
              湖南旷真律师事务所荣获“长沙市律师行业专业特色奖”、湖南旷真律师事务所主任罗迎朝荣获“长沙市律师行业优秀管理奖”
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年6月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团高级合伙人王晓华女士代表旷真参加全国建筑施工机械租赁行业峰会，湖南旷真律师事务所正式成为全国建筑机械租赁与安全管理理事会独家指定法律顾问单位。
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年6月</div>
          <div class="contentMsg">
            <div>
              由湖南省金属材料商会、天贸钢铁网联合主办，旷真法律集团冠名的“旷真杯”湖南钢贸销售50强暨年度行业杰出人物评选颁奖典礼隆重召开
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年5月</div>
          <div class="contentMsg">
            <div>
              湖南省金属材料商会迁址揭牌仪式暨旷真法律集团驻点商会法律服务工作室成立仪式顺利举行
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年5月</div>
          <div class="contentMsg">
            <div>
              由钢之家与旷真法律集团携手举办的2019年钢铁产业链发展形势研讨会暨华中第六次矿煤焦钢风险管理论坛在长沙召开
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年4月</div>
          <div class="contentMsg">
            <div>
              陕西省沧州商会专属法律服务单位-湖南旷真（西安）律师事务所授牌仪式隆重举行，湖南旷真（西安）律师事务所正式成为陕西省沧州商会专属法律服务单位
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年4月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团混凝土事业部总监胡刚先生受邀参加由湘潭市混凝土协会主办的《新时期混凝土行业的现金流管理》专题讲座，作为培训专家进行了一场关于混凝土行业挑战的培训讲座，集团创始合伙人张日照先生作为应邀嘉宾也出席了本次讲座。
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年4月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团高级合伙人王晓华女士，旷真法律集团混凝土事业部总监胡刚先生代表旷真出席湖南省混凝土与水泥制品协会2019年第一次常务理事会议，胡刚先生作为协会的特邀嘉宾受邀作行业专题报告
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年4月</div>
          <div class="contentMsg">
            <div>
              湖南旷真（广州）律师事务所正式成为广东省建筑机械租赁行业协会第二届理事会副会长单位
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年4月</div>
          <div class="contentMsg">
            <div>
              湖南省混凝土与水泥制品协会2019年第一次常务理事会议召开，旷真法律集团高级合伙人王晓华女士、旷真法律集团混凝土事业部总监胡刚先生受邀参与本次会议，并开展混凝土行业法律知识讲堂
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年3月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团助力湖南省金属材料商会换届会员代表大会暨2019年湖南钢铁产业链互联网发展论坛顺利召开
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年3月</div>
          <div class="contentMsg">
            <div>
              兰州祁连山水泥商砼有限公司总经理张学龙先生携公司高层来到旷真法律集团总部举行授牌仪式，正式授牌旷真法律集团为甘肃祁连山商砼总公司优秀战略合作法律顾问单位
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年3月</div>
          <div class="contentMsg">
            <div>
              兰州市混凝土砂浆行业协会会长金俊山先生带领协会副会长单位及骨干企业主要负责人一行24人莅临长沙参观旷真法律集团总部
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年3月</div>
          <div class="contentMsg">
            <div>旷真法律集团正式决定在北京设立分支机构，张超先生任负责人</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年3月</div>
          <div class="contentMsg">
            <div>
              株洲市混凝土行业协会工作会议暨新时期混凝土行业交流座谈会顺利召开。旷真法律集团高级合伙人王晓华女士受邀出席本次会议，并就新时期混凝土行业现金流管理等问题进行了深入交流
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年3月</div>
          <div class="contentMsg">
            <div>
              中国模板及脚手架行业的领军展会——“第十一届中国国际模板脚手架施工技术及产品设备展览会”在北京中国国际展览中心隆重举办，旷真法律集团架管事业部总监徐晓玮女士在展会论坛上发表讲话，将旷真法律服务模式介绍给更多的行业企业
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年3月</div>
          <div class="contentMsg">
            <div>
              广东省预拌混凝土行业协会2019年春茗联谊会成功举办，本次会议由珠海市水泥制品行业协会携手旷真法律集团联合承办
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年2月</div>
          <div class="contentMsg">
            <div>
              中国建筑业协会混凝土分会在黑龙江省召开了2019年全国混凝土协会各省市会长秘书长联席会，本次会议由中国建筑业协会混凝土分会携手旷真法律集团联合主办
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年2月</div>
          <div class="contentMsg">
            <div>
              由旷真法律集团、钢之家网站联合主办的2019年钢铁产业链发展形势研讨会暨西北矿煤焦钢风险管理论坛在西安正式召开
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年2月</div>
          <div class="contentMsg">
            <div>
              由钢之家网站携手旷真法律集团联合主办《2019年钢铁产业链发展形势研讨会暨南京第六次矿煤焦钢风险管理论坛》在南京顺利召开
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2019年2月</div>
          <div class="contentMsg">
            <div>湖南旷真（上海）律师事务所正式注册成功</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2018">
        <div class="littleTip">
          <div class="timeMsg">2018年12月</div>
          <div class="contentMsg">
            <div>
              在智联招聘2018中国年度最佳雇主活动中被评选为“2018中国年度优选雇主”
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年12月</div>
          <div class="contentMsg">
            <div>
              应iCourt特别邀请，旷真法律集团高级合伙人王晓华女士代表旷真参与了iCourt
              主任年会，并发表“律师业结构性思考”与“旷真的尝试”演讲
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年12月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团荣膺中国建筑业协会混凝土分会第六届理事会常务理事单位
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年11月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团助力2018年浙江省混凝土协会年会暨30周年纪念大会成功举办
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年11月</div>
          <div class="contentMsg">
            <div>旷真法律集团支持南京混凝土协会长沙交流会顺利召开</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年11月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团南京分支机构正式开业，昭示着“旷真模式”将在南京再书新篇章
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年11月</div>
          <div class="contentMsg">
            <div>成为深圳市水泥及制品协会副会长单位</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年11月</div>
          <div class="contentMsg">
            <div>湖南旷真（西安）律师事务所首次启动模拟法庭</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年11月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团携手钢之家网站联合举办2019年钢铁产业链发展形势高峰论坛
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年10月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团携手中国建筑业协会混凝土分会共同举办的2018年度中国混凝土年会暨绿色高性能发展论坛
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年10月</div>
          <div class="contentMsg">
            <div>
              集团发布了《至2019年5月20日集团发展规划》，在全集团明确了短期内的业绩目标和工作方向
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年10月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团正式冠名“旷真杯”第一届南京法律人足球联赛，助力比赛的成功举办
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年10月</div>
          <div class="contentMsg">
            <div>甘肃祁连山商砼总公司领导一行莅临旷真法律集团指导交流</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年10月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团上海军团完成选址、入驻、装修工作，正式开展业务
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年9月</div>
          <div class="contentMsg">
            <div>
              湖南旷真（西安）律师事务所加入西安钢材行业协会，出席并支持西安钢材协会举办的“国花瓷”活动、中秋团拜会等多项活动
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年9月</div>
          <div class="contentMsg">
            <div>
              湖南旷真（乌鲁木齐）律师事务所成为新疆金属材料行业商会会员，乌鲁木齐分所主任张超任副会长，开启了湖南旷真（乌鲁木齐）律师事务所进军钢材领域的大门
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年9月</div>
          <div class="contentMsg">
            <div>湖南旷真（乌鲁木齐）律师事务所搬迁新址</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年9月</div>
          <div class="contentMsg">
            <div>湖南旷真（重庆）律师事务所搬迁新址</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年8月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团携手钢之家网站在广东佛山举办《2018年(广东)矿煤焦钢市场发展形势研讨会暨风险管理论坛》
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年8月</div>
          <div class="contentMsg">
            <div>
              由旷真法律集团、我的钢铁网联合主办的《2018中国（第七届）建筑用钢产业链高端论坛》在长沙隆重召开
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年8月</div>
          <div class="contentMsg">
            <div>旷真法律集团出席全国高性能混凝土技术交流盛会</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年7月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团助力广州市混凝土行业协会第二届第五次会员大会成功举办
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年7月</div>
          <div class="contentMsg">
            <div>旷真法律集团受邀参加华菱星马汽车集团2018年中商务大会</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年7月</div>
          <div class="contentMsg">
            <div>旷真领导圈一届一次会议召开，旷真领导圈正式成立</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年7月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团参与钢之家纵横钢市俱乐部第四十七次矿煤焦钢沙龙
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年6月</div>
          <div class="contentMsg">
            <div>旷真法律集团旷真大学第一届金牌讲师训练营第一阶完美收官</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年5月</div>
          <div class="contentMsg">
            <div>湖南旷真（西安）律师事务所正式获批成立</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年5月</div>
          <div class="contentMsg">
            <div>旷真法律集团九周年庆典在总部长沙举行</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年5月</div>
          <div class="contentMsg">
            <div>
              集团务虚会召开，会议明确了组织的使命、愿景，就“旷真人是旷真的命脉”达成了共识
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年4月</div>
          <div class="contentMsg">
            <div>四川澹真律师事务所（集团成都分支机构）搬迁新址</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年4月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团参加甘肃祁连山商砼总公司法律顾问机构招标大会，并顺利中标，成为甘肃祁连山商砼总公司唯一认可的法律顾问机构
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年3月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团加入甘肃省钢材商会，并以副会长身份出席甘肃省钢材商会2017年年会暨文艺演出
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年3月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团出席兰州钢之家论坛举办的2018年钢铁产业链发展形势研讨会暨甘肃第六次矿煤焦钢风险管理论坛
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年3月</div>
          <div class="contentMsg">
            <div>
              旷真法律集团参加甘肃祁连山商砼总公司召开的2018年首次营销人员培训会，针对销售合同风险管控进行专项培训
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年3月</div>
          <div class="contentMsg">
            <div>
              广州市混凝土行业协会第十次会议于湖南旷真（广州）律师事务所召开
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年3月</div>
          <div class="contentMsg">
            <div>湖南旷真（重庆）律师事务所正式注册成功</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年1月</div>
          <div class="contentMsg">
            <div>旷真法律集团出席兰州市混凝土砂浆行业协会会议</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2018年1月</div>
          <div class="contentMsg">
            <div>
              重庆诚冠律师事务所作为协办单位参与重庆市混凝土协会2017年年会
            </div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2017">
        <div class="littleTip">
          <div class="timeMsg">2017年12月</div>
          <div class="contentMsg">
            <div>
              湖南旷真（广州）律师事务所成为广州市混凝土行业协会会员单位
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2017年10月</div>
          <div class="contentMsg">
            <div>
              珠海军团受珠海市水泥制品行业协会邀请，对全市混凝土企业进行《企业经营责任风险控制》培训
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2017年7月</div>
          <div class="contentMsg">
            <div>湖南旷真（乌鲁木齐）律师事务所成立</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2017年7月</div>
          <div class="contentMsg">
            <div>旷真珠海军团进驻珠海</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2017年6月</div>
          <div class="contentMsg">
            <div>成为湖南省混凝土与水泥制品协会常务理事单位</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2016">
        <div class="littleTip">
          <div class="timeMsg">2016年6月</div>
          <div class="contentMsg">
            <div>获评湖南省规范管理示范律师事务所</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2016年1月</div>
          <div class="contentMsg">
            <div>湖南北斗辅成资产管理有限公司在长沙成立</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2015">
        <div class="littleTip">
          <div class="timeMsg">2015年3月</div>
          <div class="contentMsg">
            <div>年度优秀律师事务所</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2014">
        <div class="littleTip">
          <div class="timeMsg">2014年3月</div>
          <div class="contentMsg">
            <div>律师事务所行政管理先进单位</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2014年1月</div>
          <div class="contentMsg">
            <div>长沙市邵阳商会2013年度优秀会员单位</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2013">
        <div class="littleTip">
          <div class="timeMsg">2013年5月</div>
          <div class="contentMsg">
            <div>四川澹真律师事务所成立</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2013年1月</div>
          <div class="contentMsg">
            <div>湘潭市建筑器材租赁商会副会长单位</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2012">
        <div class="littleTip">
          <div class="timeMsg">2012年6月</div>
          <div class="contentMsg">
            <div>北京北斗辅成风险管理顾问有限公司在海淀分局登记成立</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2012年6月</div>
          <div class="contentMsg">
            <div>长沙市律师协会颁发的“2011年度优秀律师事务所”</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2012年3月</div>
          <div class="contentMsg">
            <div>
              北京北斗辅成风险管理顾问有限公司成为成都市钢材流通商会理事单位
            </div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2011">
        <div class="littleTip">
          <div class="timeMsg">2011年10月</div>
          <div class="contentMsg">
            <div>四川省湖南商会第三届理事会副会长单位</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2011年10月</div>
          <div class="contentMsg">
            <div>法律援助工作站</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2011年7月</div>
          <div class="contentMsg">
            <div>
              重庆诚冠律师事务所成立，致力于建材领域整体法律服务的专业机构
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2011年10月</div>
          <div class="contentMsg">
            <div>长沙建筑业协会建筑施工设备租赁分会副会长单位</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2011年10月</div>
          <div class="contentMsg">
            <div>长沙市司法局颁发的“2010年度优秀律师事务所”</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2011-2014年</div>
          <div class="contentMsg">
            <div>长沙市政府投资项目法律服务定点单位</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2010">
        <div class="littleTip">
          <div class="timeMsg">2010-2011年</div>
          <div class="contentMsg">
            <div>年度长沙市优秀律师事务所</div>
          </div>
        </div>
      </van-step>
      <van-step class="contont bg2009">
        <div class="littleTip">
          <div class="timeMsg">2009-2013年</div>
          <div class="contentMsg">
            <div>中国基建物资承包协会、中国模板协会租赁委员会会员单位</div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2009年5月</div>
          <div class="contentMsg">
            <div>
              湖南旷真律师事务所成立，致力于凭借深厚的人文底蕴和丰富的法律服务执业经验为社会提供优质的法律服务
            </div>
          </div>
        </div>
        <div class="littleTip">
          <div class="timeMsg">2009年</div>
          <div class="contentMsg">
            <div>北斗辅成集团成立</div>
          </div>
        </div>
      </van-step>
    </van-steps>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.memorabiliaBox {
  width: 100%;
  margin: auto;
  padding: 1rem 0;
}
.littleTip {
  display: flex;
  margin-bottom: 0.1rem;

  .timeMsg {
    flex: 4;
    text-align: right;
    color: #ab0000;
    font-weight: 700;
    padding-left: 0.3rem;
    box-sizing: border-box;
  }

  .contentMsg {
    box-sizing: border-box;
    flex: 9;
    text-align: left;
    padding-left: 0.2rem;
    padding-right: 0.5rem;

    > div {
      margin-bottom: 8px;
      color: #333333;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
  }
}
.contont {
  background-size: 100%;
  background-repeat: no-repeat;
  //   box-shadow: -0.2rem 1rem 0.22rem 0px rgba(0, 0, 0, 0.03);
  background-color: rgba(255, 255, 255, 0.1);
  padding-top: 1rem;
}

.bg2009 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2009.png);
}

.bg2010 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2010.png);
}

.bg2011 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2011.png);
}

.bg2012 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2012.png);
}

.bg2013 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2013.png);
}

.bg2014 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2014.png);
}

.bg2015 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2015.png);
}

.bg2016 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2016.png);
}

.bg2017 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2017.png);
}

.bg2018 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2018.png);
}

.bg2019 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2019.png);
}

.bg2020 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2020.png);
}

.bg2021 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2021.png);
}

.bg2022 {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/memorabilia/2022.png);
}

.bg2023 {
  background-image: url(./guanyukuangzhen/2023.png);
}
.bg2024 {
  background-image: url(./guanyukuangzhen/2024.png);
}
</style>
