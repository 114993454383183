<template>
  <div>
    <img src="./map.png" alt="" class="kuangzhenMap" />
    <div class="main" style="width: 90%; margin: auto">
      <div class="msgTips">
        <p>
          旷真规模化建设的同时，不断思考和研究新一代法律服务产品，将精益法律服务推向更多的行业。
        </p>
      </div>
      <div class="citys">
        <div class="haveCity">
          <div class="title">已有城市</div>
          <div class="cityNames">
            北京、上海、深圳、广州、长沙、苏州、天津、南京、杭州、合肥、济南、青岛、重庆、成都、贵阳、珠海、武汉、南昌、西安、郑州、太原、沈阳、乌鲁木齐、兰州、厦门、银川、海口、惠州、东莞、无锡、宁波
          </div>
        </div>
        <!-- <div class="haveCity">
                <div class="title">21-22年计划</div>
                <div class="cityNames">
                    昆明、宁波、苏州、石家庄、银川、青岛、沈阳、福州
                </div>
            </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.kuangzhenMap {
  width: 100%;
  margin: 1rem auto;
}
.main {
  width: 95%;
  margin: 1rem auto;
  text-align: center;
  padding-bottom: 0.5rem;

  .msgTips {
    position: relative;
    width: 100%;
    height: 2rem;
    line-height: 1.5;
    background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/territory/background.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: left;
    margin: 1rem 0 0;
    color: #333;

    p {
      position: absolute;
      bottom: 0.2rem;
      left: 50%;
      transform: translateX(-49%);
      width: 90%;
    }
  }

  .citys {
    width: 95%;
    margin: -10px auto 1rem;
    background-color: rgba(63, 60, 60, 0.02);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    box-sizing: border-box;
    border-radius: 10px;

    .haveCity {
      text-align: left;

      .title {
        color: #666666;
      }

      .cityNames {
        color: #999999;
        margin: 0.2rem 0;
      }
    }
  }
}
</style>
